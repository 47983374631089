.trainers__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 4rem;
}

.card.trainer {
    padding: 1rem;
}

.trainer__img {
    border-radius: 0 2rem;
    overflow: hidden;
}

.trainer h3 {
    margin-top: 2rem;
}

.trainer p {
    margin-top: 0.5rem;
}


/* MEDIA QUERIES (medium screens) */
@media screen and (max-width: 1024px) {
    .trainers__container {
        grid-template-columns: 1fr 1fr;
        gap: 2.5rem;
    }
}


/* MEDIA QUERIES (small screens) */
@media screen and (max-width: 600px) {
    .trainers__container {
        grid-template-columns: 1fr;
        gap: 2rem;
    }

    .card.trainer {
        width: 90%;
        margin-inline: auto;
    }
}
