.about__section-image {
    border-radius: 50%;
    transform: var(--transition);
}

.about__section-image:hover {
    transform: scale(120%);
}

.about__section-content h1 {
    margin-bottom: 2rem;
}

.about__section-content p {
    margin-bottom: 1rem;
}

.about__story-container, .about__mission-container {
    display: grid;
    grid-template-columns: 40% 50%;
    gap: 10%;
}

.about__vision-container {
    display: grid;
    grid-template-columns: 50% 40%;
    gap: 10%;
}



/* MEDIA QUERIES (MEDIUM SCREENS) */
@media screen and (max-width: 1024px) {

    .about__section-image {
        width: 60%;
    }

    .about__section-content h1 {
        margin-bottom: 1.2rem;
    }

    .about__story-container, .about__vision-container, .about__mission-container {
        grid-template-columns: 1fr;
        gap: 3rem;
    }

    .about__vision .about__section-image {
        grid-row: 1;
    }
}



/* MEDIA QUERIES (small SCREENS) */
@media screen and (max-width: 600px) {
    .about__section-image {
        width: 80%;
        margin-inline: auto;
    }

    .about__story-container, .about__vision-container, .about__mission-container {
        gap: 2rem;
    }
}